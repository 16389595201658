<template>
  <div class="nav">
    <ul class="list">
      <li class="item" v-for="(item,index) in leftList" :key="index">
        <div class="item-left">
          <p class="title">
            <i :class="['iconfont', item.frontName]"></i>
            {{item.name}}
          </p>
          <div class="key">
            <a
              class="name"
              v-for="(v,vIndex) in item.subCategoryList"
              :key="vIndex"
              @click="clickNav(vIndex,v)"
            >{{v.name}}</a>
            <a class="name" @click="clickMore(index,item)">更多</a>
          </div>
        </div>
        <div class="item-right"></div>
      </li>
      <li class="icon">
        <a v-for="(o,oIndex) in rightList" :key="oIndex">
          <img :src="o.image" alt />
          <p>{{o.name}}</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    leftList: {
      type: Array,
      default: () => []
    },
    rightList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @param {String} 当前列表
     * @param {Number}} index
     */
    clickNav(index, params) {
      this.$emit("clickNav", index, params);
    },
    /**
     * @param {String} 当前列表
     * @param {Number}} index
     */
    clickMore(index, params) {
      this.$emit("clickMore", index, params);
    }
  }
};
</script>

<style lang="scss" scoped>
.nav {
  margin-top: 40px;
  margin-bottom: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .list {
    display: flex;
    width: 100%;
    height: 100%;
    li {
      width: 21.5%;
      box-sizing: border-box;
      display: flex;
      &:nth-child(1) {
        .title .iconfont {
          color: #0773fc;
        }
      }
      &:nth-child(2) {
        .title .iconfont {
          color: #f12e5a;
        }
      }
      &:nth-child(3) {
        .title .iconfont {
          color: #02bb00;
        }
      }
      &:nth-child(4) {
        .title .iconfont {
          color: #ff782b;
        }
      }
      .item-left,
      .item-right {
        display: inline-block;
      }
      .item-left {
        position: relative;
        width: 246px;
        height: 100%;
        .title,
        .name {
          font-size: 14px;
          color: #333;
        }
        .title {
          margin-bottom: 16px;
          font-weight: 700;
          text-align: left;
          .iconfont {
            margin-right: 9px;
          }
        }
        .key {
          margin-left: -30px;
          .name {
            float: left;
            margin: 0 0 10px 30px;
            cursor: pointer;
            &:hover {
              color: #0773fc;
            }
          }
        }
      }
      .item-right {
        margin-top: 32px;
        width: calc((100% - 246px) / 2);
        height: 48px;
        border-right: 1px solid #eee;
      }
      &.icon {
        width: 14% !important;
        display: flex;
        align-items: center;
        justify-content: space-around;
        a {
          display: flex;
          align-items: center;
          flex-direction: column;
          color: #333;
          cursor: pointer;
          &:hover {
            img {
              transform: translateY(-6px);
            }
          }
          img {
            cursor: pointer;
            width: 60px;
            height: 60px;
            vertical-align: center;
            transition: all 0.2s;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .nav .list li .item-left{
    width: 170px;
  }
}
</style>