<template>
  <div class="home">
    <slide :leftList="leftBannerList" :rightList="rightBannerList"></slide>
    <i-nav :leftList="navLeftList" :rightList="navRightList" @clickNav="clickNav" @clickMore="clickNavMore"></i-nav>
    <slot name="bannerSearch"></slot>
    <i-hot :title="'素材网站'" :list="hotList"></i-hot>
    <list :title="'热门下载'" :list="hotShowList" @preview="preview"></list>
  </div>
</template>

<script>
import slide from "./module/slide.vue";
import iNav from "./module/nav.vue";
import iHot from "./module/hot.vue";
import list from "./module/list.vue";
export default {
  components: {
    slide,
    iNav,
    iHot,
    list
  },
  props: {
    leftBannerList: {
      type: Array,
      default: () => []
    },
     rightBannerList: {
      type: Array,
      default: () => []
    },
     navLeftList: {
      type: Array,
      default: () => []
    },
     navRightList: {
      type: Array,
      default: () => []
    },
     hotList: {
      type: Array,
      default: () => []
    },
    hotShowList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      
    };
  },
  mounted() {},
  methods: {
    //点击导航列表
    clickNav(index, params) {},
    //点击导航更多
    clickNavMore(index, params) {},
    preview(index,params) {
      this.$emit("preview", index,params);
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  padding-bottom: 50px;
}
</style>