<template>
  <div class="home-content">
    <div class="title-box">
      <span class="home-title">{{title}}</span>
    </div>
    <image-list :list="list" @preview="preview"></image-list>
  </div>
</template>

<script>
import imageList from "@/components/imageList/index.vue";
export default {
  components: {
    imageList
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  methods: {
    preview(index,params) {
      this.$emit("preview", index,params);
    }
  }
};
</script>

<style lang="scss" scoped>
.home-title {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-size: 26px;
  font-weight: 700;
}
.hot-content {
  margin-top: 10px;
  height: auto;
  position: relative;
  overflow: hidden;
}
</style>