<template>
  <div :class="['hot-show-list', classlistName]">
    <ul class="list">
      <li v-for="(item,index) in list" :key="index">
        <div class="item">
          <img :src="item.primaryPicUrl" alt />
          <p class="title">{{item.keywords}}</p>
          <div class="top" @click="preview(index,item)">
            <el-tooltip
              class="item"
              effect="light"
              content="预览"
              placement="bottom"
              :enterable="false"
            >
              <i class="iconfont iconyulan"></i>
            </el-tooltip>
          </div>
          <div class="bottom">
            <p class="name">{{item.goodsSn}} {{item.brandName}}</p>
            <div class="text">
              <span class="label">价格：{{item.retailPrice}}</span>
              <span class="line"></span>
              <span class="label">类型：{{item.attributeCategoryName}}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    classlistName: {
      type: String,
      default: "num6"
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    preview(index, params) {
      this.$emit("preview", index, params);
    }
  }
};
</script>

<style lang="scss" scoped>
.hot-show-list {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &.num5 {
    li {
      margin-left: 25px;
      width: calc((100% - 100px) / 5);
      &:nth-child(5n + 1) {
        margin-left: 0;
      }
    }
  }
  &.num6 {
    li {
      width: calc((100% - 100px) / 6);
      &:nth-child(6n + 1) {
        margin-left: 0;
      }
    }
  }
  li {
    float: left;
    margin-bottom: 32px;
    margin-left: 20px;
    position: relative;
    height: 343px;
    transition: transform 0.3s;
    box-sizing: border-box;
    &:hover {
      z-index: 9;
      transform: scale(1.2);
      box-shadow: 0 30px 28px 0, rgba(17, 36, 73, 0.14);
      img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .top {
        display: block;
      }
      .title {
        display: none;
      }
      .bottom {
        display: block;
        box-shadow: 0 1px 8px 0 #a5a5a5;
      }
    }
    img {
      position: relative;
      width: 100%;
      height: 240px;
      display: block;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      z-index: 4;
    }
    .title {
      padding-top: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #000;
    }
    .top {
      display: none;
      position: absolute;
      top: 11px;
      left: 11px;
      transform: scale(0.8);
      cursor: pointer;
      width: 30px;
      height: 30px;
      line-height: 30px;
      opacity: 0.7;
      background: #000;
      border-radius: 50%;
      text-align: center;
      z-index: 99;
      .iconfont {
        font-size: 12px;
        color: #e8e8e8;
      }
    }
    .bottom {
      margin-top: -2px;
      display: none;
      position: relative;
      overflow: hidden;
      padding: 13px 10px 20px 10px;
      background: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      z-index: 2;
      .name {
        margin-top: 2px;
        font-weight: 700;
        font-size: 12px;
      }
      .text {
        margin: 8px 0 12px 0;
        .label {
          font-size: 12px;
          color: #666;
        }
        .label,
        .line {
          float: left;
        }
        .line {
          display: block;
          margin: 0 10px;
          width: 1px;
          height: 18px;
        }
      }
    }
  }
}
</style>