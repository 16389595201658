/**
 * Created by Administrator on 2019/10/7.
 */
import { request } from '../utils/request'
import serviceInterface from '../api/serviceInterface'

/**
 * 获取首页信息
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const getMainList = (params) => {
    return request({
        url: serviceInterface.index.index,
        params: params
    })
}

/**
 * 获取首页信息
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const getFrontList = (params) => {
    return request({
        url: serviceInterface.index.frontIndex,
        params: params
    })
}

/**
 * 根据位置获取获取广告信息
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const getBannerList = (params) => {
    return request({
        url: serviceInterface.index.banner,
        params: params
    })
}


/**
 * 获取各热门网站列表
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const brandsList = (params) => {
    return request({
        url: serviceInterface.brand.brandsList,
        params: params
    })
}
