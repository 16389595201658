<template>
  <div class="slider">
    <div class="slider-left">
      <div class="list">
        <img
          v-for="(item,index) in leftList"
          :key="index"
          :src="item.image_url"
          @click="clickBanner(item.link)"
          :class="{active: index==slideCurrent}"
          alt
        />
      </div>
      <a class="arrow-left" @click="prevSlide"></a>
      <a class="arrow-right" @click="nextSlide"></a>
      <ol class="spans">
        <li
          v-for="(v,vIndex) in spans"
          :key="vIndex"
          :class="{active: vIndex==slideCurrent}"
          @click="clickSlide(vIndex)"
        ></li>
      </ol>
    </div>
    <div class="slider-right" v-for="(o,oIndex) in rightList" :key="oIndex">
      <ul class="item">
        <li v-for="(s,sIndex) in o.children" :key="sIndex">
          <img :src="s.image" alt />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftList: {
      type: Array,
      default: () => []
    },
    rightList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    spans() {
      let arr = [];
      if (this.leftList.length > 0) {
        this.leftList.forEach((item, index) => {
          arr[index] = {};
        });
      }
      return arr;
    }
  },
  data() {
    return {
      slideCurrent: 0,
      time: 0
    };
  },
  mounted() {
    this.animation();
  },
  methods: {
    animation() {
      this.time = setInterval(() => {
        this.slideCurrent++;
        if (this.slideCurrent == this.leftList.length) {
          this.slideCurrent = 0;
        }
      }, 5000);
    },
    clickBanner(url){
      if(url === '') {
        return;
      }else {
        window.open(url);
      }
    },
    //点击轮播图
    clickSlide(index) {
      this.slideCurrent = index;
      clearInterval(this.time);
      this.animation();
    },
    //上一张
    prevSlide() {
      this.slideCurrent--;
      if (this.slideCurrent < 0) {
        this.slideCurrent = this.leftList.length - 1;
      }
    },
    //下一张
    nextSlide() {
      this.slideCurrent++;
      if (this.slideCurrent == this.leftList.length) {
        this.slideCurrent = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.radius {
  border-radius: 6px;
}
.slider {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &,
  .slider-left,
  .slider-right {
    position: relative;
    height: 300px;
    overflow: hidden;
  }
  .slider-left {
    width: 63%;
    @extend .radius;
    &:hover {
      .arrow-left,
      .arrow-right {
        display: block;
      }
    }
    .list {
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;
      img {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &.active {
          display: block;
        }
      }
    }
    .arrow-left,
    .arrow-right {
      display: none;
      position: absolute;
      top: 50%;
      width: 48px;
      height: 48px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 9;
      background: rgba(0, 0, 0, 0.46);
      -webkit-transition: opacity 0.1s linear, background 0.1s linear;
      transition: opacity 0.1s linear, background 0.1s linear;
      border-radius: 50%;
      cursor: pointer;
      &::after {
        position: absolute;
        top: 52%;
        right: 6px;
        margin-top: -6px;
        display: inline-block;
        content: " ";
        height: 8px;
        width: 8px;
        border-width: 2px 2px 0 0;
        border-color: hsla(0, 0%, 100%, 0.8);
        border-style: solid;
        -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
        transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      }
      &:hover {
        background: rgba(0, 0, 0, 0.6);
      }
    }
    .arrow-left {
      left: 20px;
      &::after {
        right: 37%;
        border-width: 0 0 2px 2px;
      }
    }
    .arrow-right {
      right: 20px;
      &::after {
        right: 44%;
      }
    }
    .spans {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        display: inline-block;
        overflow: hidden;
        margin: 0 10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.4);
        border: 1px solid hsla(0, 0%, 100%, 0.2);
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        &.active {
          background: #fff;
          border: 1px solid#b7b7b7;
        }
      }
    }
  }
  .slider-right {
    width: 17.2%;
    .item {
      position: relative;
      cursor: pointer;
      li {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 141px;
        cursor: pointer;
        &:first-child {
          margin-bottom: 16px;
        }
        img {
          width: 100%;
          height: 100%;
          @extend .radius;
        }
      }
    }
  }
}
</style>
