<template>
  <div class="home-content">
    <p class="home-title">{{title}}</p>
    <div class="hot-content">
      <ul :class="['hot-list', {slide: !showArrow}]">
        <li v-for="(item,index) in list" :key="index">
          <img :src="item.appListPicUrl" alt />
          <span class="themeTxt">
            <p>{{item.name}}</p>
            <p>{{item.simpleDesc}}</p>
          </span>
        </li>
      </ul>
    </div>
    <div class="arrow arrowL" v-show="!showArrow" @click="prevClick"></div>
    <div class="arrow arrowR" v-show="showArrow" @click="nextClick"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showArrow: true
    };
  },
  methods: {
    prevClick() {
      this.showArrow = true;
    },
    nextClick() {
      this.showArrow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.home-content {
  box-sizing: border-box;
  position: relative;
  .arrow {
    position: absolute;
    top: 50%;
    // margin-top: -16px;
    height: 32px;
    width: 32px;
    transform: translateY(-50%);
    z-index: 1;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 6px 12px 0 rgba(48, 62, 83, 0.14);
    border-radius: 50%;
    &::after {
      display: inline-block;
      content: " ";
      height: 6px;
      width: 6px;
      border-width: 0 0 1.5px 1.5px;
      border-color: #1b2337;
      border-style: solid;
      transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
      position: absolute;
      top: 55%;
      right: 12px;
      margin-top: -6px;
    }
  }
  .arrowL {
    left: -12px;
  }
  .arrowR {
    right: -12px;
    &::after {
      right: 42%;
      border-width: 1.5px 1.5px 0 0;
    }
  }
}
.home-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-size: 26px;
  font-weight: 700;
}
.hot-content {
  margin-top: 10px;
  height: auto;
  position: relative;
  overflow: hidden;
}
.hot-list {
  padding-top: 10px;
  padding-bottom: 56px;
  position: relative;
  left: 0;
  overflow: hidden;
  width: 300%;
  height: 152px;
  white-space: nowrap;
  transition: 0.7s;
  font-size: 0;
  &.slide {
    left: -100%;
  }
  li {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 250px;
    cursor: pointer;
    transition: all 0.15s;
    box-sizing: content-box;
    border-radius: 6px;
    &:hover {
      transform: translateY(-8px);
      box-shadow: 0 30px 36px -12px rgba(12, 21, 35, 0.16);
    }
    &:nth-child(5) {
      margin-right: 0;
    }
    img {
      width: 100%;
    }
  }
  .themeTxt {
    position: absolute;
    left: 16%;
    top: 25%;
    p {
      &:first-child {
        font-size: 26px;
        color: #333;
        // letter-spacing: 8.67px;
        font-weight: 700;
        width: 6em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:last-child {
        margin-top: 4px;
        font-size: 14px;
        color: #666;
        letter-spacing: 2px;
      }
    }
  }
}
</style>